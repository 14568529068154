import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles/carte_employe_equipe.module.css'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

interface Props {
    titre: string,
    role: string,
    description: string,
    image: string,
    url: string
}

function addHttps(url: string) {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
}

function isValidUrl(url: string): boolean {
  const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return urlRegex.test(url);
}

function CarteEmployeEquipe(props: Props) {

    const hasValidUrl = props.url && isValidUrl(props.url);

    return (
        <div className={styles.card}>
        {hasValidUrl ? (
            <img className={styles.thumbnail_partenaire} src={`${props.image}`} />
          ) : (
            <img className={styles.thumbnail} src={`${props.image}`} />
          )}
            <div className={styles.texte_carte}>
                <h2 className={styles.titre}>{props.titre}</h2>
                <h4>{props.role}</h4>
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: props.description.replace(/\n/g, '<br/>') }} />

                     <div>
                         {hasValidUrl ? (
                           <a href={addHttps(props.url)} className='global_regular_link_light_bg' >{props.url}<FontAwesomeIcon icon={faExternalLink} style={{ paddingLeft: '5px', paddingRight: '-10px', fontSize: '0.85rem' }} /></a>
                         ) : (
                           <span className='global_regular_link_light_bg' ></span>
                         )}
                     </div>

            </div>
        </div>
    );
}

export default CarteEmployeEquipe;





// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import styles from '../styles/carte_employe_equipe.module.css'
// import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

// interface Props {
//     titre: string,
//     role: string,
//     description: string,
//     image: string,
//     url: string
// }

// function addHttps(url: string) {
//   if (!/^https?:\/\//i.test(url)) {
//     url = "https://" + url;
//   }
//   return url;
// }

// function isValidUrl(url: string): boolean {
//   const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
//   return urlRegex.test(url);
// }

// function CarteEmployeEquipe(props: Props) {

//     const hasValidUrl = props.url && isValidUrl(props.url);
//     const hasImage = !!props.image; // Convert to boolean

//     return (

//       <div className={styles.card}>


//         <div className={styles.imageContainer}>
//           {hasImage ? (
//             <img className={styles.thumbnail_partenaire} src={`${props.image}`} alt={props.titre} />
//           ) : (
//             <div className={styles.placeholder}></div>
//           )}
//         </div>


//             <div className={styles.texte_carte}>
//                 <h2 className={styles.titre}>{props.titre}</h2>
//                 <h4>{props.role}</h4>
//                 <p className={styles.description} dangerouslySetInnerHTML={{ __html: props.description.replace(/\n/g, '<br/>') }} />
//                 {
//                     <div>
//                         {hasValidUrl ? (
//                           <a href={addHttps(props.url)} className='global_regular_link_light_bg' >{props.url}<FontAwesomeIcon icon={faExternalLink} style={{ paddingLeft: '5px', paddingRight: '-10px', fontSize: '0.85rem' }} /></a>
//                         ) : (
//                           <span className='global_regular_link_light_bg' ></span>
//                         )}
//                     </div>
//                 }
//             </div>
//         </div>
//     );
// }

// export default CarteEmployeEquipe;
